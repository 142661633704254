import { IActionContext, ITelemetry, ICreateActionContext, IGeneric, IAny } from '@msdyn365-commerce/core';
import { OrgUnitLocation, ProductSearchResult, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { ICommerceApiSettings } from '@msdyn365-commerce/core-internal';
import { storeSelectorStateDataAction, IStoreSelectorStateManager } from '@msdyn365-commerce-modules/bopis-utilities';
// @ts-ignore
import { MapStoreSelectorStateManager } from '../modules/mmx-store-selector/state-manager/map-store-selector-state-manager';
import {
    GenericInput,
    getDeliveryOptionsForSelectedVariant,
    GetDeliveryOptionsForSelectedVariantInput,
    getOrgUnitLocationsByArea,
    GetOrgUnitLocationsByAreaInput
} from '@msdyn365-commerce-modules/retail-actions';
import React from 'react';

/**
 * Assigns given pickup location to given product.
 */
export const setPickupLocationForProduct = async (
    product: SimpleProduct,
    quantity: number,
    onLocationSelected: (orgUnitLocation: OrgUnitLocation, deliveryMode: string) => Promise<void>,
    storeSelectorId: string,
    modalCloseButtonReference: React.RefObject<HTMLButtonElement>,
    channelId: number,
    actionContext: IActionContext,
    telemetry?: ITelemetry
): Promise<undefined> => {
    let storeSelectorStateManager = await storeSelectorStateDataAction(
        new GenericInput<IStoreSelectorStateManager>('mapSSSM', new MapStoreSelectorStateManager(), 'IStoreSelectorStateManager'),
        actionContext
    ).catch((error: Error) => {
        if (telemetry) {
            telemetry.error(error.message);
            telemetry.debug('Error finding store selector state manager');
        }
    });

    if (storeSelectorStateManager) {
        const deliveryOptions = await getDeliveryOptionsForSelectedVariant(
            new GetDeliveryOptionsForSelectedVariantInput(product.RecordId, channelId, undefined, undefined, true),
            actionContext
        );

        let locations = await getOrgUnitLocationsByArea(
            new GetOrgUnitLocationsByAreaInput(undefined, undefined, 0, 0, true),
            actionContext
        );

        if (locations && locations.length) {
            if (locations.length > 1) {
                await storeSelectorStateManager
                    .openDialog({
                        product: product,
                        id: storeSelectorId ?? 'mmx-store-selector',
                        parentElementRef: modalCloseButtonReference,
                        deliveryOptions: deliveryOptions,
                        additionalProperties: {
                            amountSelected: quantity,
                            isStockedProuct: product.Behavior?.IsStockedProduct
                        },
                        onLocationSelected: onLocationSelected
                    })
                    .catch((error: any) => {
                        if (telemetry) {
                            telemetry.warning(error);
                            telemetry.debug('[order-template-lines] Error trying to Update Cart Line from the order template lines');
                        }
                    });
                return Promise.resolve(undefined);
            } else {
                if (locations[0].OrgUnitAvailability && locations[0].OrgUnitAvailability?.OrgUnitLocation) {
                    const defaultDeliveryOption = deliveryOptions?.DeliveryOptions ? deliveryOptions.DeliveryOptions[0].Code : undefined;
                    if (defaultDeliveryOption) {
                        onLocationSelected(locations[0].OrgUnitAvailability.OrgUnitLocation, defaultDeliveryOption);
                        return Promise.resolve(undefined);
                    } else {
                        return Promise.reject('Could not find value delivery options');
                    }
                } else {
                    return Promise.reject('No valid org unit availability object');
                }
            }
        } else {
            return Promise.reject('No location found');
        }
    }
    return Promise.reject('Could not set pickup location');
};
/**
 * Utility function to extract the active productId in the following priority:
 * First query param (productId), then UrlToken (itemId), then module config.
 * Shamelessly stolen from msdyn commerce modules.
 * @param inputData The Action Input data.
 */
export const getSelectedProductIdFromActionInput = (inputData: ICreateActionContext<IGeneric<IAny>>): string | undefined => {
    if (inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query['product-id']) {
        return inputData.requestContext.query['product-id'];
    } else if (inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.productId) {
        return inputData.requestContext.query.productId;
    } else if (
        inputData &&
        inputData.requestContext &&
        inputData.requestContext.urlTokens &&
        inputData.requestContext.urlTokens.pageType &&
        inputData.requestContext.urlTokens.pageType !== 'Category' &&
        inputData.requestContext.urlTokens.itemId
    ) {
        return inputData.requestContext.urlTokens.itemId;
    } else if (inputData && inputData.config && inputData.config.productId) {
        return inputData.config.productId;
    }
    return undefined;
};

/**
 * Generates a Image URL for a product based on data return from retail server.
 * @param product The Product returned by Retail Server.
 * @param apiSettings
 */
export const generateProductImageUrl = (
    product: SimpleProduct | ProductSearchResult,
    apiSettings: ICommerceApiSettings
): string | undefined => {
    const fullImagePath = /^(\/\/|^)http/;
    const PRODUCT_DELINEATOR_INDEX = 0;
    const PRODUCT_SEQ_INDEX = 4;

    let imageUrl = product.PrimaryImageUrl;
    if (imageUrl) {
        if (fullImagePath.test(imageUrl)) {
            return imageUrl;
        }
        let imageUrlDecoded = decodeURIComponent(imageUrl);
        let imageComponents = imageUrlDecoded.split(' ^');
        let imageUrlRebuilt = imageComponents[PRODUCT_DELINEATOR_INDEX];
        if (imageComponents.length > 1) {
            imageUrlRebuilt += imageComponents[PRODUCT_SEQ_INDEX];
        }
        // Images hosted in Retail Server must be encoded and joined with the base image url
        return apiSettings.baseImageUrl + encodeURIComponent(imageUrlRebuilt);
    }
    return undefined;
};

/**
 * Gets item at provided cache key from the cache of a state data action. If multiple results are found, return only the first one.
 * @param context The request context
 * @param cacheObjectType The object type returned by the state data action
 * @param cacheKey The name of the state data action
 */
export function getSingleItemFromStateAction<T>(context: IActionContext, cacheObjectType: string, cacheKey: string): T | undefined {
    const result = context.get<T>(cacheObjectType, cacheKey);
    if (!result) {
        return undefined;
    } else if (Object.prototype.toString.call(result) !== '[object Array]') {
        //@ts-ignore
        return result;
    }
    return result[0];
}
