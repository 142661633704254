import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { getSelectedProductIdFromActionInput } from '../../utilities/utils';
import { IWarrantyOption } from '../DataServiceEntities.g';

export class ProductWarrantyStateInput implements IActionInput {
    public productId: number;

    constructor(productId: number) {
        this.productId = productId;
    }

    public getCacheKey = () => `ProductWarrantyState-${this.productId}`;
    public getCacheObjectType = () => 'IWarrantyOption';
    public dataCacheType = (): CacheType => 'instance';
}

export async function productWarrantyStateAction(input: ProductWarrantyStateInput, ctx: IActionContext): Promise<IWarrantyOption> {
    return <IWarrantyOption>{};
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const selectedId = getSelectedProductIdFromActionInput(inputData);
    if (selectedId) {
        return new ProductWarrantyStateInput(+selectedId);
    }
    throw new Error('Unable to find product warranty state, no productId found on module config or query');
};

export default createObservableDataAction<IWarrantyOption>({
    action: <IAction<IWarrantyOption>>productWarrantyStateAction,
    input: createInput
});
